<template>
  <div class="bt-transaksi pa-5">
    <h6 class="text-h5 font-weight-bold indigo--text text--lighten-1 d-flex align-center">
      <div class="rounded-pill indigo lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
        <v-icon small color="white">mdi-book-multiple</v-icon>
      </div>
      Laporan Saldo Tabungan
    </h6>
    <div>
      <v-select solo label="Majelis" class="mb-3 mt-3" hide-details :items="opt.rembug" v-model="form.data.kode_rembug"
        @change="getAnggota()" />

      <v-select solo label="Pilih Anggota" class="mb-3" hide-details :items="opt.anggota" v-model="form.data.no_anggota"
        @change="getRekening()" />

      <v-select solo label="Pilih Rekening Tabungan" class="mb-3" hide-details :items="opt.rekening"
        v-model="form.data.no_rekening" />

      <v-btn color="blue-grey mt-0" class="mx-auto white--text" block @click="getSaving()">
        Search
        <v-icon right>mdi-magnify</v-icon>
      </v-btn>
    </div>
    <v-container class="pa-0 mt-5">
      <p class="text-h6 text-center" v-if="form.data.saving.length == 0" style="color: white;">
        Data not found!
      </p>
      <v-card class="white elevation-3 rounded-lg pa-3 mb-3" v-for="(item, index) in form.data.saving" :key="index">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="text-right" style="width: 50%;">
                  No Rekening
                </td>
                <td style="width: 1%;">:</td>
                <td class="text-left font-weight-black">
                  {{ item.no_rekening }}
                </td>
              </tr>
              <tr>
                <td class="text-right">
                  Nama Anggota
                </td>
                <td>:</td>
                <td class="text-left font-weight-black">
                  {{ item.nama_anggota }}
                </td>
              </tr>
              <tr>
                <td class="text-right">
                  Nama Produk
                </td>
                <td>:</td>
                <td class="text-left font-weight-black">
                  {{ item.nama_produk }}
                </td>
              </tr>
              <tr>
                <td class="text-right">
                  Saldo
                </td>
                <td>:</td>
                <td class="text-right font-weight-black">
                  {{ thousandMask(item.saldo)[0] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay v-model="overlay" class="align-center justify-center">
      <v-progress-circular color="primary" size="64" indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import helper from "@/utils/helper";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
export default {
  name: "SaldoTabungan",
  components: {},
  data() {
    return {
      form: {
        data: {
          kode_rembug: null,
          no_anggota: null,
          no_rekening: null,
          saving: [],
        }
      },
      opt: {
        rembug: [],
        anggota: [],
        rekening: [],
      },
      dateShow: false,
      alert: {
        show: false,
        msg: ''
      },
      overlay: false,
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async getRembug() {
      try {
        this.overlay = true;
        this.opt.rembug = [];

        const {
          kode_cabang = null
        } = this.user;

        let payload = new FormData();
        payload.append("kode_cabang", kode_cabang);

        const response = await services.rembugRead(payload, this.user.token);
        if (response.status === 200) {
          response.data.data.map((item) => {
            this.opt.rembug.push({
              text: item.nama_rembug,
              value: item.kode_rembug,
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || '',
        };
      } finally {
        this.overlay = false;
      }
    },
    async getAnggota() {
      try {
        this.overlay = true;
        this.opt.anggota = []

        const {
          kode_cabang = null
        } = this.user;

        let payload = new FormData()
        payload.append("kode_cabang", kode_cabang);
        payload.append('kode_rembug', this.form.data.kode_rembug)
        payload.append('no_anggota', null);

        const response = await services.anggotaRead(payload, this.user.token)
        if (response.status === 200) {
          response.data.data.map((item) => {
            this.opt.anggota.push({
              value: item.no_anggota,
              text: item.nama_anggota,
              data: item
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || ''
        }
      } finally {
        this.overlay = false;
      }
    },
    async getRekening() {
      try {
        this.overlay = true;
        this.opt.rekening = []

        let payload = new FormData();
        payload.append('no_anggota', this.form.data.no_anggota);

        const response = await services.getRekeningTabungan(payload, this.user.token)
        if (response.status === 200) {
          response.data.data.map((item) => {
            this.opt.rekening.push({
              value: item.no_rekening,
              text: `${item.no_rekening} - ${item.nama_produk}`,
              data: item
            });
          });
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || ''
        }
      } finally {
        this.overlay = false;
      }
    },
    async getSaving() {
      try {
        this.overlay = true;
        this.form.data.saving = [];

        if (this.form.data.kode_rembug == null && this.form.data.no_anggota == null && this.form.data.no_rekening == null) {
          this.alert = {
            show: true,
            msg: 'Please Select!',
          }
          return;
        }

        let payload = new FormData()
        payload.append('no_rekening', this.form.data.no_rekening);

        const response = await services.getLaporanListGetDetailSaving(payload, this.user.token)
        if (response.status === 200 && response.data.data && response.data.data !== null) {
          this.form.data.saving = [response.data.data];
        } else {
          this.alert = {
            show: true,
            msg: response.data.msg || null,
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || ''
        }
      } finally {
        this.overlay = false;
      }
    },
  },
  mounted() {
    this.getRembug();
  },
};
</script>
